// A flag file to help more easily toggle feature deploys
// where contributions span across multiple PRs

import useTenantIdDoesMatch from "./useTenantIdDoesMatch";
import { isProductionEnvironment } from "../environment";

const DACS_DEMO_TENANT_IDS = new Set([
  "tenant_d9397751-2c42-4940-9839-42a4a3e5ac1c",
]);

export const useFlags = () => {
  const isDacsClient = useTenantIdDoesMatch(DACS_DEMO_TENANT_IDS);
  const isProd = isProductionEnvironment();

  return {
    isCustomConverterEnabled: true, // controls the Settings Page custom converter experiment
    isAssignmentEnabled: true, // controls the assignment UI
    isMultipartUploadEnabled: true, // controls the multipart upload UI
    isCaseSummaryEnabled: true, // controls the case summary UI
    isDacsClient, // controls the DACS Clients UI
    shouldHideNestedIntegrations: true, // controls whether nested integrations are hidden
    isAssetMappingEnabled: true, // controls the asset mapping UI
    isMultipleWalletUploadEnabled: true, // controls the multiple wallet upload UI
    shouldShowJSONLUpload: false, // shows the JSONL file upload UI
    isWalletUploadEnabled: true,
    isFileConverterEnabled: !isProd,
  };
};
