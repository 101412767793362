import { FileUpload, SourceStatus } from "../fileGetApiTypes";

export type ProcessingSourceStatus =
  | SourceStatus.INITIALIZED
  | SourceStatus.SYNC_IN_PROGRESS
  | SourceStatus.NORMALIZATION_IN_PROGRESS
  | SourceStatus.FILE_INGESTION_IN_PROGRESS
  | SourceStatus.TRANSFER_DETECTION_IN_PROGRESS;

export type FailedProcessingSourceStatus =
  | SourceStatus.SYNC_FAILED
  | SourceStatus.NORMALIZATION_FAILED
  | SourceStatus.FILE_INGESTION_FAILED
  | SourceStatus.TRANSFER_DETECTION_FAILED;

export type FailedDeleteSourceStatus = SourceStatus.DELETE_FAILED;

export const isSourceStatusInProgress = (status: FileUpload["status"]) =>
  isStatusInitialized(status) ||
  isStatusNormalizationInProgress(status) ||
  isStatusSyncInProgress(status) ||
  isStatusFileIngestionInProgress(status) ||
  isStatusTransferDetectionInProgress(status);

export const isStatusProcessingFailed = (status: FileUpload["status"]) =>
  isStatusNormalizationFailed(status) ||
  isStatusSyncFailed(status) ||
  isStatusFileIngestionFailed(status) ||
  isStatusTransferDetectionFailed(status);

export const isStatusInitialized = (status: FileUpload["status"]) =>
  [SourceStatus.INITIALIZED].includes(status as SourceStatus);

export const isStatusSyncInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.SYNC_IN_PROGRESS].includes(status as SourceStatus);

const isStatusSyncFailed = (status: FileUpload["status"]) =>
  [SourceStatus.SYNC_FAILED].includes(status as SourceStatus);

const isStatusNormalizationInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.NORMALIZATION_IN_PROGRESS].includes(status as SourceStatus);

export const isStatusNormalizationFailed = (status: FileUpload["status"]) =>
  [SourceStatus.NORMALIZATION_FAILED].includes(status as SourceStatus);

const isStatusFileIngestionInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.FILE_INGESTION_IN_PROGRESS].includes(status as SourceStatus);

const isStatusFileIngestionFailed = (status: FileUpload["status"]) =>
  [SourceStatus.FILE_INGESTION_FAILED].includes(status as SourceStatus);

export const isStatusTransferDetectionInProgress = (
  status: FileUpload["status"]
) =>
  [SourceStatus.TRANSFER_DETECTION_IN_PROGRESS].includes(
    status as SourceStatus
  );

const isStatusTransferDetectionFailed = (status: FileUpload["status"]) =>
  [SourceStatus.TRANSFER_DETECTION_FAILED].includes(status as SourceStatus);

export const isStatusDeleteInProgress = (status: FileUpload["status"]) =>
  [SourceStatus.DELETE_IN_PROGRESS].includes(status as SourceStatus);

export const isStatusDeleteFailed = (status: FileUpload["status"]) =>
  [SourceStatus.DELETE_FAILED].includes(status as SourceStatus);

export const isStatusReady = (status: FileUpload["status"]) =>
  [SourceStatus.READY].includes(status as SourceStatus);

export const isStatusConverted = (status: FileUpload["status"]) =>
  [SourceStatus.CONVERTED].includes(status as SourceStatus);
