import { StringType } from "@taxbit-private/type-wrappers";
import { z } from "zod";

import zodLaxNativeEnum from "../../../types/zodLaxNativeEnum";
import { customTxnRulesSchema } from "../imputation-methods/imputationMethodsGetApiTypes";

export type AsedDate = StringType<"AsedDate">;
export type CaseName = StringType<"CaseName">;
export type CaseCreationDate = StringType<"CaseCreationDate">;
export const caseIdSchema = z.string().brand("CaseId");
export type CaseId = z.infer<typeof caseIdSchema>;
export type CaseUpdatedDate = StringType<"CaseUpdatedDate">;
export type UserId = StringType<"UserId">;

export enum CaseStatus {
  CREATED = "CREATED",
  IN_PROCESS = "IN-PROCESS",
  DELIVERED = "DELIVERED",
  COMPLETE = "COMPLETE",
  ARCHIVED = "ARCHIVED",
  Unknown = "Unknown",
}

export const caseStatusSchema = zodLaxNativeEnum(CaseStatus);

export const assigneeSchema = z.union([
  z.literal("UNASSIGNED"),
  z.string().uuid(),
]);
export type AssigneeOption = z.infer<typeof assigneeSchema>;

export enum DispositionMethod {
  ByAccountFifo = "by-account-fifo",
  ExchangeSpecificId = "exchange-specific-id",
  Fifo = "fifo",
  UniversalHifo = "universal-hifo",
  UniversalLifo = "universal-lifo",

  /**
   * These two disposition methods don't exist in the backend enum but are attached to real cases,
   * probably through manual backend creation. We maintain them in the enum to avoid schema parse failures
   * even though no case should be created with these methods via the UI.
   *
   * BE source: https://github.com/taxbit-private/darts-case-api/blob/master/ts/src/model/case.ts#L25
   */
  Hifo = "hifo",
  EnterpriseTaxHifo = "enterprise-tax-hifo",
}

export const caseSchema = z.object({
  asedDate: z
    .string()
    .nullable()
    .optional()
    .transform((date) => date as AsedDate),
  caseName: z.string().transform((date) => date as CaseName),
  createdDate: z.string(),
  id: z.string(),
  status: caseStatusSchema,
  updatedDate: z.string(),
  userId: z.string(),
  years: z.array(z.number()),
  assignee: assigneeSchema,
  dispositionMethod: z.nativeEnum(DispositionMethod),
  tenant: z.string().optional(),
  processError: z.string().optional(),
  completed: z.number().optional(),
  customTxnRules: z.optional(customTxnRulesSchema).nullable(),
  calcsCompletedTimestamp: z.string().optional(),
  calcsFailedTimestamp: z.string().optional(),
  transferDetectionFailedTimestamp: z.string().optional(),
  fiatAssetId: z.string().uuid(),
  fiatAssetCode: z.string(),
});

export type Case = z.infer<typeof caseSchema>;

export const getCasesSchema = z.array(caseSchema);

export type GetCasesResponse = z.infer<typeof getCasesSchema>;
